<a
  class="sidenav-item relative mx-2 my-1 flex select-none items-center gap-2 rounded-lg border border-transparent px-2 py-1.5 transition duration-100 ease-in-out hover:border-slate-300 hover:bg-slate-100 active:bg-slate-200"
  #routerLinkActive="routerLinkActive"
  [routerLink]="path()"
  [routerLinkActiveOptions]="routerLinkActiveOptions()"
  matRipple
  routerLinkActive="bg-slate-200 hover:bg-slate-200 !border-slate-300 font-medium">
  <mat-icon
    class="flex-none text-slate-500"
    [ngClass]="{ '!text-primary-600 font-medium': routerLinkActive.isActive }"
    >{{ icon() }}</mat-icon
  >
  <span class="flex-1 truncate">{{ label() }}</span>
</a>
