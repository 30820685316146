import { Injectable, effect, signal } from '@angular/core';
import { AbilityBuilder } from '@casl/ability';
import { Observable } from 'rxjs';

import { AppSharedUtilAuthenticatedUserService } from '@husky/app-shared-util-auth';
import {
  ActivityCommentFragment,
  ActivityFragment,
  EmailFragment,
  MediaFragment,
  NoteFragment,
  ProjectFragment,
  TimeEntryFragment,
  TimeEntryStatus,
  UserFragment,
  UserRole,
} from '@husky/app/shared/data-access';
import { AbilitySubject } from '@husky/shared-util-ability';
import { FieldPath } from '@husky/shared/util';

import { AppAbility, createAppAbility } from './ability';

/**
 * https://github.com/stalniy/casl/blob/master/packages/casl-angular/src/AbilityService.ts
 */
@Injectable({
  providedIn: 'root',
})
export class AppSharedUtilAbilityService {
  user = this.authenticatedUserService.user;
  ability = signal<AppAbility>(this.appAbility, {
    /** TODO: It only works when this is set to false, this is because the ability is mutated on update and signals don't emit when the reference changes, but any other check doesn't work, even isEqual from lodash */
    equal: () => false,
  });

  readonly ability$: Observable<AppAbility> = new Observable((observer) => {
    observer.next(this.appAbility);
    return this.appAbility.on('updated', () => observer.next(this.appAbility));
  });

  constructor(
    private readonly authenticatedUserService: AppSharedUtilAuthenticatedUserService,
    private readonly appAbility: AppAbility,
  ) {
    this.ability$.subscribe((ability) => {
      this.ability.set(ability);
    });

    effect(
      () => {
        this.updateAbilityForUser(this.user());
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  private updateAbilityForUser(user: UserFragment | undefined): void {
    const { can, cannot, rules } = new AbilityBuilder(createAppAbility);

    if (!user) {
      this.appAbility.update([]);
      return;
    }

    if (user.role === UserRole.Admin) {
      can(
        ['create', 'read', 'list', 'update', 'delete', 'approve'],
        [
          AbilitySubject.Activity,
          AbilitySubject.ActivityComment,
          AbilitySubject.Contact,
          AbilitySubject.Media,
          AbilitySubject.Email,
          AbilitySubject.Employee,
          AbilitySubject.EmployeeContract,
          AbilitySubject.Note,
          AbilitySubject.Health,
          AbilitySubject.Project,
          AbilitySubject.ProjectCalculation,
          AbilitySubject.User,
          AbilitySubject.Invoice,
          AbilitySubject.Product,
          AbilitySubject.TimeEntry,
          AbilitySubject.Supplier,
          AbilitySubject.SupplierProduct,
          AbilitySubject.PurchaseInvoice,
          AbilitySubject.PurchaseInvoiceLine,
          AbilitySubject.PurchaseOrder,
          AbilitySubject.QuickEstimate,
          AbilitySubject.QuotePreview,
          AbilitySubject.Warehouse,
          AbilitySubject.WarehouseReceipt,
          AbilitySubject.WarehouseStock,
          AbilitySubject.WarehouseTransaction,
        ],
      );
    }

    if ([UserRole.Sales, UserRole.FieldService].includes(user.role)) {
      can(
        ['create', 'read', 'list', 'update'],
        [
          AbilitySubject.Activity,
          AbilitySubject.Contact,
          AbilitySubject.Project,
        ],
      );

      can(['create', 'read', 'list'], [AbilitySubject.ActivityComment]);
      /** Can update and delete their own activity comments */
      can(['update', 'delete'], AbilitySubject.ActivityComment, {
        ['createdBy.id' satisfies FieldPath<ActivityCommentFragment>]: user.id,
      });

      can('delete', AbilitySubject.Activity, {
        ['createdBy.id' satisfies FieldPath<ActivityFragment>]: user.id,
      });

      can(['create', 'read', 'list', 'update'], AbilitySubject.Project);
      /** Cannot update project number and assigned users */
      cannot('update', AbilitySubject.Project, [
        'projectNumber',
        'assignedUsers',
      ] satisfies FieldPath<ProjectFragment>[]);
      /** Can update project number and assigned users on projects they created */
      can(
        'update',
        AbilitySubject.Project,
        [
          'projectNumber',
          'assignedUsers',
        ] satisfies FieldPath<ProjectFragment>[],
        {
          ['createdBy.id' satisfies FieldPath<ProjectFragment>]: user.id,
        },
      );

      /** Allow sales to read and list media, notes and emails */
      can(
        ['read', 'list'],
        [AbilitySubject.Media, AbilitySubject.Note, AbilitySubject.Email],
      );

      /** Allow sales to update and delete their own media, notes and emails */
      can(
        ['update', 'delete'],
        [AbilitySubject.Media, AbilitySubject.Note, AbilitySubject.Email],
        {
          ['createdBy.id' satisfies FieldPath<MediaFragment> &
            FieldPath<NoteFragment> &
            FieldPath<EmailFragment>]: user.id,
        },
      );

      can(['read', 'list'], AbilitySubject.User);

      /** Allow to read, list, create and update time entries */
      can(['read', 'list', 'create'], AbilitySubject.TimeEntry, {
        ['user.id' satisfies FieldPath<TimeEntryFragment>]: user.id,
      });

      /** Allow to update own time entries when status = Pending */
      can('update', AbilitySubject.TimeEntry, {
        ['user.id' satisfies FieldPath<TimeEntryFragment>]: user.id,
        ['status' satisfies FieldPath<TimeEntryFragment>]:
          TimeEntryStatus.Pending,
      });

      /** Allow to update own time entries when status = Pending */
      can('update', AbilitySubject.TimeEntry, {
        ['createdBy.id' satisfies FieldPath<TimeEntryFragment>]: user.id,
        ['status' satisfies FieldPath<TimeEntryFragment>]:
          TimeEntryStatus.Pending,
      });

      /** Allow to delete own time entries when status = Pending */
      can('delete', AbilitySubject.TimeEntry, {
        ['user.id' satisfies FieldPath<TimeEntryFragment>]: user.id,
        ['status' satisfies FieldPath<TimeEntryFragment>]:
          TimeEntryStatus.Pending,
      });

      /** Allow to delete own time entries when status = Pending */
      can('delete', AbilitySubject.TimeEntry, {
        ['createdBy.id' satisfies FieldPath<TimeEntryFragment>]: user.id,
        ['status' satisfies FieldPath<TimeEntryFragment>]:
          TimeEntryStatus.Pending,
      });
    }

    if (user.role === UserRole.Sales) {
      can(['create'], AbilitySubject.QuickEstimate);
    }

    this.appAbility.update(rules);
  }
}
