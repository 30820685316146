@if (mapsAddressString(); as mapsAddressString) {
  <iframe
    class="aspect-square bg-slate-100"
    [src]="mapsAddressString"
    style="border: 0; width: 100%"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade">
  </iframe>
}
