import { Component, input } from '@angular/core';

import { fadeInOut } from '@husky/app/shared/util-animation';

@Component({
  selector: 'husky-loading-overlay',
  standalone: true,
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
  animations: [fadeInOut()],
})
export class LoadingOverlayComponent {
  loading = input.required<boolean | null>();
  backgroundOverlay = input<boolean>(true);
  align = input<'center' | 'top' | 'bottom'>('center');
  position = input<'absolute' | 'relative'>('absolute');
  text = input<string>('Wird geladen...');
}
