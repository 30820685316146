import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, effect, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import * as Sentry from '@sentry/angular';
import {
  EMPTY,
  catchError,
  combineLatest,
  filter,
  interval,
  startWith,
  switchMap,
} from 'rxjs';

import {
  AuthenticatedUserGQL,
  UserFragment,
} from '@husky/app/shared/data-access';

import { AppSharedUtilAuthService } from './app-shared-util-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppSharedUtilAuthenticatedUserService {
  user = signal<UserFragment | undefined>(undefined);
  user$ = toObservable(this.user);

  constructor(
    private readonly authenticatedUserGQL: AuthenticatedUserGQL,
    private readonly authService: AppSharedUtilAuthService,
  ) {
    effect(() => {
      Sentry.setUser(this.user() ? { id: this.user()?.id } : null);
    });
  }

  startPollingAuthenticatedUser() {
    combineLatest([
      this.authService.isAuthenticated$,
      interval(30000).pipe(startWith(null)),
    ])
      .pipe(
        filter(([isAuthenticated]) => isAuthenticated),
        switchMap(() =>
          this.authenticatedUserGQL.fetch().pipe(
            catchError((error) => {
              console.error('Failed to fetch authenticated user', error);

              /** If the network error is a 500, we do not log out the user */
              if ((error.networkError as HttpErrorResponse)?.status === 500) {
                console.log('Network error');
                return EMPTY;
              }

              if (this.user()) {
                this.authService.logout();
              }

              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe((result) => {
        this.user.set(result.data?.me);

        if (result.error) {
          console.error(
            'Failed to fetch authenticated user, but received data in ApolloQueryResult',
            result,
          );
          this.authService.logout();
        }
      });
  }
}
