import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-drawer-content',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-shared-ui-drawer-content.component.html',
  styleUrl: './app-shared-ui-drawer-content.component.scss',
  host: {
    class: 'block px-gutter pb-gutter flex-1 overflow-auto',
    style: '-webkit-overflow-scrolling: touch;',
  },
})
export class AppSharedUiDrawerContentComponent {}
